import React, { useEffect } from "react";
// import { makeStyles, Theme, createStyles } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { getPlace } from "../../../apis/googleMapsApiAction";
import { moveGMapsPoint } from "../GoogleMaps";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
// import { userLogging } from "../../../apis/userLog";

// const useStyles = makeStyles((theme: Theme) =>
//   createStyles({
//     button: {
//       position: "absolute",
//       left: "15px",
//       // 横幅から判定して検索ボタンを上にずらす
//       [theme.breakpoints.down("sm")]: {
//         top: "50px",
//       },
//       [theme.breakpoints.up("md")]: {
//         top: "150px",
//       },
//       backgroundColor: "#fff",
//       "&:hover": {
//         background: "#d9d9d9",
//       },
//       height: "40px",
//       width: "40px",
//       padding: "10px",
//     },
//     searchMenu: {
//       position: "absolute",
//       left: "10px",
//       // 横幅から判定して検索ボタンを上にずらす
//       [theme.breakpoints.down("sm")]: {
//         top: "50px",
//       },
//       [theme.breakpoints.up("md")]: {
//         top: "150px",
//       },
//       width: "320px",
//       height: "52px",
//       border: "solid thin #ddd",
//       borderRadius: "4px",
//       zIndex: 100,
//       backgroundColor: "#fff",
//       padding: "2px",
//     },
//     searchTextField: {
//       margin: "2px 0px 2px 2px",
//       padding: "0px 2px",
//       height: "20px",
//     },
//     closeButton: {
//       position: "absolute",
//       top: "-5px",
//       left: "90%",
//       width: "12px",
//     },
//   })
// );

const MapsSearchBar: React.FC = (props: any) => {
  const [searchMode, setSearchMode] = React.useState(false);
  const [searchWord, setSearchWord] = React.useState("");
  // const classes = useStyles({});
  // const history = useHistory();
  useEffect(() => {}, []);
  const handleSearchMenu = (flag: boolean) => {
    setSearchMode(flag);
  };
  const handleSearchButton = () => {
    getPlace(searchWord).then((result: any) => {
      moveGMapsPoint(
        result[0].geometry.location.lat(),
        result[0].geometry.location.lng()
      );
      // userLogging("opview-map-search", searchWord)
    });
  };

  return (
    <>
      {searchMode ? (
        <>
          <Grid container className={"search-menu"}>
            <Grid container item xs={8}>
              <TextField
                className={"search-text-field"}
                value={searchWord}
                label="目的地で探す"
                variant="outlined"
                size="small"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setSearchWord(e.target.value);
                }}
              />
            </Grid>
            <Grid container item xs={3}>
              <Button
                className={"search-main-button"}
                onClick={() => handleSearchButton()}
              >
                検索する
              </Button>
            </Grid>
            <Grid container item xs={1}>
              <IconButton
                className={"close-button"}
                onClick={() => handleSearchMenu(false)}
              >
                <ArrowBackIosNewIcon fontSize="small" />
              </IconButton>
            </Grid>
          </Grid>
        </>
      ) : (
        <IconButton
          aria-label="検索"
          className={"search-button map-item-shadow"}
          onClick={() => handleSearchMenu(true)}
        >
          <SearchIcon />
        </IconButton>
      )}
    </>
  );
};

export default MapsSearchBar;
