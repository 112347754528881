/**
 * mapApiOperation.js
 * API経由での地図操作の処理を行います
 */
import { postRequest } from "../../../apis/axiosAction";
import {
  closedSejStoreLayer,
  cbFuncClosedSejStore,
  cbFuncClosedSejStoreFirst,
} from "../layers/closedSejStore";
import { point2distance } from "../../../apis/turfAction";
import {
  isDisplayZone,
  isDisplayPlanArea,
  isDisplayCloseSejStore,
} from "../MapComponents/MapsDisplayManager";

let lastQueriedLatLng: any = null;

// Firestoreの呼出
export const callMapItemApi = (
  latLng: any,
  zoomLevel = 22,
  isForceUpdate: boolean = false
) => {
  let searchLength = 3;
  const searchDistance = 3,
    largeAreaZoomLevel = 15;
  if (
    lastQueriedLatLng !== null &&
    point2distance(latLng, lastQueriedLatLng) / 1000 < searchDistance &&
    !isForceUpdate
  ) {
    return;
  }
  if (largeAreaZoomLevel > zoomLevel) {
    searchLength = 4.5;
  }
  const rviewAddress = "rview/mapitem";
  if (isDisplayCloseSejStore) {
    callAPi(
      latLng,
      "closed_sej_stores",
      cbFuncClosedSejStore,
      cbFuncClosedSejStoreFirst,
      closedSejStoreLayer,
      rviewAddress
    );
  }
  lastQueriedLatLng = latLng;
};

const callAPi = (
  latLng: any,
  layerName: string,
  callbackFunc: any,
  callbackFuncFirst: any,
  layerValue: any,
  address: string
) => {
  const json = {
    latitude: latLng.lat(),
    longtitude: latLng.lng(),
    layer_name: layerName,
  };
  if (callbackFuncFirst()) {
    return;
  }
  // ローカル開発環境はGoogleAppEngineを向けている
  const url =
    process.env.NODE_ENV === "development"
      ? "https://mega-sevenview-dev.an.r.appspot.com/" + address
      : "./" + address;
  postRequest(url, json, false, true, false)
    .then((res) => {
      if (layerName === "plan_area") {
        callbackFunc(
          JSON.parse(res.data.geojson_entrance),
          JSON.parse(res.data.geojson_building),
          JSON.parse(res.data.geojson_plan_area),
          layerValue,
          []
        );
      } else {
        callbackFunc(JSON.parse(res.data.geojson), layerValue, []);
      }
    })
    .catch((err) => {
      console.log(err);
    });
};
