/**
 * MapItems.js
 * 地図上の複数項目の管理を行います。
 * 極力個々のレイヤの処理はlayersフォルダで実装してください
 */

import { getGMapsCenterPoint, getGoogleMapsZoom } from "./GoogleMaps";
import {
  unvisibleMapsStyleSettings,
  conflictStoreLayerMapsStyleSettings,
} from "./MapStylesSettings";
import { callMapItemGeoFireStore } from "./operation/mapFirestoreOperation";
import { callMapItemApi } from "./operation/mapApiOperation";
import { callMapItemInnerData } from "./operation/mapInnerDataOperation";
import {
  initExistingSejStores,
  existingSejStoreInfoWindow,
  existingSejStoreLayer,
  existingSejStoreData,
  existingSejStoreLayerMapsStyleSettings,
} from "./layers/existingSejStoreLayer";
import {
  initConflictStores,
  conflictStoreInfoWindow,
  conflictStoreLayer,
  conflictStoreData,
} from "./layers/conflictStoreLayer";
import {
  initZone,
  zoneInfoWindow,
  zoneLayer,
  zoneData,
  zoneLayerMapsStyleSettings,
} from "./layers/zoneLayer";
import {
  initPlanArea,
  planAreaEntranceInfoWindow,
  planAreaLayer,
  planAreaData,
  planAreaLayerMapsStyleSettings,
} from "./layers/planAreaLayer";
import {
  initClosedSejStore,
  closedSejStoreInfoWindow,
  closedSejStoreLayer,
  closedSejStoreData,
  closedSejStoreLayerMapsStyleSettings,
} from "./layers/closedSejStore";

// レイヤ初期化
export const initGoogleMapsLayers = (): void => {
  initExistingSejStores();
  initConflictStores();
  initZone();
  initPlanArea();
  initClosedSejStore();
  // Firestoreをコール
  // callMapItemGeoFireStore(getGMapsCenterPoint());
  callMapItemApi(getGMapsCenterPoint());
  callMapItemInnerData(getGMapsCenterPoint());
};

// 地図操作
export const callMapItemMove = (isForceUpdate: boolean = false) => {
  // callMapItemGeoFireStore(getGMapsCenterPoint(), getGoogleMapsZoom(), isForceUpdate);
  callMapItemApi(getGMapsCenterPoint(), getGoogleMapsZoom(), isForceUpdate);
  callMapItemInnerData(
    getGMapsCenterPoint(),
    getGoogleMapsZoom(),
    isForceUpdate
  );
};

// 地図上の項目の表示管理(非表示時→表示化、表示時→非表示化)
export const manageDisplayLayerFromName = (
  layerValueName: string,
  flag: boolean
): void => {
  manageDisplayLayer(getLayerValue(null, "layerValue", layerValueName), flag);
};

// 地図上の項目の表示管理(非表示時→表示化、表示時→非表示化)
export const manageDisplayLayer = (layerValue: any, flg: boolean): void => {
  if (!flg) {
    // 既に表示されている場合非表示化する
    layerValue.setStyle(unvisibleMapsStyleSettings);
    // 表示されているInfoWindowを消す
    let infoWindow = getLayerValue(layerValue, "infoWindow");
    infoWindow.forEach(function (infoWindow: any) {
      infoWindow.close();
    });
    infoWindow.clear();
  } else {
    // 表示を行う場合既存項目を初期化した上で再表示する
    layerValue.forEach(function (feature: any) {
      layerValue.remove(feature);
    });
    layerValue.addGeoJson(getLayerValue(layerValue, "geojson"));
    layerValue.setStyle(getLayerValue(layerValue, "mapStyle"));
  }
  callMapItemMove(true);
};

// FireStoreの地図上の項目の表示アップデート(非表示時→表示化、表示時→非表示化)
export function updateFireStoreLayer(layerValue: any) {
  // 押下確認はボタンのステータスを参照する
  // 表示を行う場合既存項目を初期化した上で更新する
  if (layerValue === undefined) return;
  layerValue.forEach(function (feature: any) {
    layerValue.remove(feature);
  });
  layerValue.addGeoJson(getLayerValue(layerValue, "geojson"));
  layerValue.setStyle(getLayerValue(layerValue, "mapStyle"));
}

// レイヤ別の値をそれぞれ返す
export function getLayerValue(
  layerValue: any,
  typeName: string,
  layerName: string = ""
): any {
  // 既存店レイヤー
  if (layerValue === existingSejStoreLayer && typeName === "geojson") {
    return existingSejStoreData;
  }
  if (layerValue === existingSejStoreLayer && typeName === "mapStyle") {
    return existingSejStoreLayerMapsStyleSettings;
  }
  if (layerValue === existingSejStoreLayer && typeName === "fsDocumentName") {
    return "existing_sej_stores";
  }
  if (layerValue === existingSejStoreLayer && typeName === "infoWindow") {
    return existingSejStoreInfoWindow;
  }
  if (layerName === "existing_sej_stores" && typeName === "layerValue") {
    return existingSejStoreLayer;
  }
  // 競合店レイヤー
  if (layerValue === conflictStoreLayer && typeName === "geojson") {
    return conflictStoreData;
  }
  if (layerValue === conflictStoreLayer && typeName === "mapStyle") {
    return conflictStoreLayerMapsStyleSettings;
  }
  if (layerValue === conflictStoreLayer && typeName === "fsDocumentName") {
    return "conflict_stores";
  }
  if (layerValue === conflictStoreLayer && typeName === "infoWindow") {
    return conflictStoreInfoWindow;
  }
  if (layerName === "conflict_stores" && typeName === "layerValue") {
    return conflictStoreLayer;
  }
  // ZONEレイヤー
  if (layerValue === zoneLayer && typeName === "geojson") {
    return zoneData;
  }
  if (layerValue === zoneLayer && typeName === "mapStyle") {
    return zoneLayerMapsStyleSettings;
  }
  if (layerValue === zoneLayer && typeName === "fsDocumentName") {
    return "zone";
  }
  if (layerValue === zoneLayer && typeName === "infoWindow") {
    return zoneInfoWindow;
  }
  if (layerName === "zone" && typeName === "layerValue") {
    return zoneLayer;
  }
  // 候補地レイヤー
  if (layerValue === planAreaLayer && typeName === "geojson") {
    return planAreaData;
  }
  if (layerValue === planAreaLayer && typeName === "mapStyle") {
    return planAreaLayerMapsStyleSettings;
  }
  if (layerValue === planAreaLayer && typeName === "fsDocumentName") {
    return "plan_area";
  }
  if (layerValue === planAreaLayer && typeName === "infoWindow") {
    return planAreaEntranceInfoWindow;
  }
  if (layerName === "plan_area" && typeName === "layerValue") {
    return planAreaLayer;
  }
  // 閉店レイヤー
  if (layerValue === closedSejStoreLayer && typeName === "geojson") {
    return closedSejStoreData;
  }
  if (layerValue === closedSejStoreLayer && typeName === "mapStyle") {
    return closedSejStoreLayerMapsStyleSettings;
  }
  if (layerValue === closedSejStoreLayer && typeName === "fsDocumentName") {
    return "closed_sej_stores";
  }
  if (layerValue === closedSejStoreLayer && typeName === "infoWindow") {
    return closedSejStoreInfoWindow;
  }
  if (layerName === "closed_sej_stores" && typeName === "layerValue") {
    return closedSejStoreLayer;
  }
}
