/**
 * 圧縮ファイルの解凍処理を行います
 * Zlibというライブラリを使っている
 * https://github.com/imaya/zlib.js
 */

/**
 * GoogleCloudStorageからトークンを用いてZPIファイルダウンロードを行い、後続処理を呼び出す
 * ZIPファイルの中と内部ファイル名が拡張子以外は同一前提
 *
 * @param token GoogleCloudStorageのトークン
 * @param gcsPath GoogleCloudStorageのパス
 * @param func コールバック関数
 */
export const getGcsZipFile = async(gcsPath: string, token: string, func: any) => {
  try {
    const url = gcsPath + "?access_token=" + token ;
    var xhr = new XMLHttpRequest;
    xhr.open('GET', url, true)
    const fileName = gcsPath.split('/')[gcsPath.split('/').length -1].replace('.zip', '.csv');;
    xhr.onload = function () {
      // 後続処理でzipファイルの解凍及び後続処理を行う
      unZipFile(new Blob([xhr.response]), fileName, func);
    }
    xhr.open('GET', url, true);
    xhr.responseType = 'arraybuffer';
    xhr.send();
    return "success";
  } catch (error) {
    console.log("error", error);
    throw error;
  }
}

// ZIPファイルの解凍及びデータの読み込み、後続処理の呼び出しを行います
const unZipFile = async(zipData:any, fileName:string, func: any) => {
  const zipReader = new FileReader();
  zipReader.onload = function () {
      try {
          // @ts-ignore
          const zipArr = new Uint8Array(zipReader.result);
          // @ts-ignore
          const unzip = new Zlib.Unzip(zipArr);
          const data = new TextDecoder().decode(unzip.decompress(fileName));
          func(data);
      } catch (e) {
        console.log(e);
      }
  }
  zipReader.readAsArrayBuffer(zipData);
}
