/**
 * Google Maps関係のコントローラークラスです。
 * @namespace GoogleMap
 * @class GoogleMaps.ts
 * @constructor
 *
 * React版のGoogle Maps ライブラリではGeoJSONやDrawingManerの利用が困難なため、
 * 素のTypesctriptでコーディングする
 */
import { createMobakuMap } from "./MobakuMap";
import { initGoogleMapsLayers, callMapItemMove } from "./MapItems";

let googleMapObject: google.maps.Map;
let trafficLayer: any, transitLayer: any;

export const getGoogleMapsObject = (): google.maps.Map => {
  return googleMapObject;
};

export function createGoogleMap(): void {
  // GoogleMapsAPI用の値
  const googleMapsSettingsValue = {
    INITIAL_CENTER:
      localStorage.getItem("lastCenterPoint") === null
        ? [139.7321702, 35.6856134]
        : [
            JSON.parse(localStorage.getItem("lastCenterPoint") ?? "139.7321702")
              .lon,
            JSON.parse(localStorage.getItem("lastCenterPoint") ?? "35.6856134")
              .lat,
          ],
    INITIAL_ZOOMLV:
      localStorage.getItem("lastZoomLevel") === null
        ? 17
        : parseInt(localStorage.getItem("lastZoomLevel") ?? "17"),
    DEFALUT_MAP_TYPE: "roadmap",
    ZOOM_MIN: 3,
    ZOOM_MAX: 21,

    CENTER_MOVED_ZOOM: 15, // 住所検索などにより中心座標が移動した際のズームレベル変更の境界レベル

    ZOOM_MODE_CHANGE: 2, //拡大、縮小モード時に一度に変更するズームレベルの値
  };

  let googleMapsOptionsSettings = {
    center: new google.maps.LatLng(
      googleMapsSettingsValue.INITIAL_CENTER[1],
      googleMapsSettingsValue.INITIAL_CENTER[0]
    ),
    mapTypeId: googleMapsSettingsValue.DEFALUT_MAP_TYPE,
    zoom: googleMapsSettingsValue.INITIAL_ZOOMLV,
    mapId: "e42bfbd48d30d1a0", // Google Mapsのスタイル
    disableDefaultUI: false,
    keyboardShortcuts: false,
    draggable: true,
    disableDoubleClickZoom: false,
    scrollwheel: true,
    streetViewControl: true,
    maxZoom: googleMapsSettingsValue.ZOOM_MAX,
    minZoom: googleMapsSettingsValue.ZOOM_MIN,
    streetViewControlOptions: {
      position: google.maps.ControlPosition.LEFT_BOTTOM, //左下配置
    },
    mapTypeControl: false,
    zoomControl: true, // ズームボタンの表示
    scaleControl: true, // 縮尺の表示
    clickableIcons: false, // 地図上に存在するPOIアイコンをクリックできるか否かを指定する
    scaleControlOptions: {
      position: google.maps.ControlPosition.BOTTOM_RIGHT, //右下配置
      style: google.maps.ScaleControlStyle.DEFAULT, //標準のスケール
    },
    fullscreenControl: false,
    gestureHandling: "greedy",
  };

  googleMapObject = new google.maps.Map(
    document.getElementById("map") as HTMLElement,
    googleMapsOptionsSettings
  );
  trafficLayer = new google.maps.TrafficLayer();
  transitLayer = new google.maps.TransitLayer();

  // 地図上ドラッグイベント管理
  google.maps.event.addListener(googleMapObject, "dragend", () => {
    // firebaseAnalyticsSelectContent("地図操作");
    callMapItemMove();
  });
  // 中心点の移動
  google.maps.event.addListener(googleMapObject, "center_changed", () => {
    localStorage.setItem(
      "lastCenterPoint",
      JSON.stringify({
        lon: getGMapsCenterPoint().lng(),
        lat: getGMapsCenterPoint().lat(),
      })
    );
  });
  // ZOOMレベル変更
  google.maps.event.addListener(googleMapObject, "zoom_changed", () => {
    // @ts-ignore
    localStorage.setItem("lastZoomLevel", getGoogleMapsObject().getZoom());
  });
  //右クリックの処理
  google.maps.event.addListener(googleMapObject, "rightclick", () => {
    console.log("rightclick");
    // controlContextMenu(true, event);
  });
  // ロングタップの処理開始(開始)
  google.maps.event.addListener(googleMapObject, "mousedown", () => {
    // startLongTap(event);
  });
  // ロングタップの処理開始(終了)
  google.maps.event.addListener(googleMapObject, "mouseup", () => {
    // endLongTap(event);
  });
  createMobakuMap(googleMapObject);
  initGoogleMapsLayers();
}

// 道路地図のGoogleMapsを表示します
export function setRoadGoogleMap() {
  // firebaseAnalyticsSelectContent("道路地図");
  googleMapObject.setMapTypeId("roadmap");
}
// 航空写真地図のGoogleMapsを表示します
export function setSatelliteGoogleMap() {
  // firebaseAnalyticsSelectContent("航空写真");
  googleMapObject.setMapTypeId("satellite");
}
// 地形情報地図のGoogleMapsを表示します
export function setTerrainGoogleMap() {
  // firebaseAnalyticsSelectContent("地形情報地図");
  googleMapObject.setMapTypeId("terrain");
}
// 衛星写真ハイブリッド地図のGoogleMapsを表示します
export function setHybridGoogleMap() {
  // firebaseAnalyticsSelectContent("ハイブリッド地図");
  googleMapObject.setMapTypeId("hybrid");
}
// 交通状況のGoogleMapsを表示管理を行います(false:表示しない,true:表示)
export const setTrafficLayerGoogleMap = (isDisplay: boolean) => {
  // firebaseAnalyticsSelectContent("交通状況");
  isDisplay ? trafficLayer.setMap(googleMapObject) : trafficLayer.setMap(null);
};
// 鉄道のGoogleMapsを表示管理を行います(false:表示しない,true:表示)
export const setTransitLayerGoogleMap = (isDisplay: boolean) => {
  // firebaseAnalyticsSelectContent("鉄道");
  isDisplay ? transitLayer.setMap(googleMapObject) : transitLayer.setMap(null);
};
// 地図上の中心座標を取得する
export const getGMapsCenterPoint: any = () => {
  return googleMapObject.getCenter();
};
// 指定したGoogleMapsの中心座標に移動する
export const moveGMapsPoint = (
  lat: number,
  lng: number,
  zoomLevel: number = 18,
  isCallGeoFireStore: boolean = true
) => {
  const latlng = new google.maps.LatLng(lat, lng);
  getGoogleMapsObject().panTo(latlng);
  setGoogleMapsZoom(zoomLevel);
  if (isCallGeoFireStore) {
    callMapItemMove();
  }
};
// 指定したGoogleMapsの範囲内に移動する
export const moveGMapsBounds = (latLngBounds: any) => {
  googleMapObject.panToBounds(latLngBounds);
};
// ズームレベルを設定する
export const setGoogleMapsZoom = (zoomLevel: number) => {
  getGoogleMapsObject().setZoom(zoomLevel);
};

// ズームレベルを取得する
export const getGoogleMapsZoom = (): number => {
  return getGoogleMapsObject().getZoom() ?? 22;
};
