import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import "firebase/analytics";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
  authDomain: process.env.REACT_APP_FIREBASE_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

const firebaseApp = firebase.initializeApp(firebaseConfig);

export const db = firebaseApp.firestore();
export const firestore = firebase.firestore;
export const auth = firebase.auth();
export const storage = firebase.storage();
export const analytics = firebase.analytics();
// export const provider = new firebase.auth.GoogleAuthProvider();

// Firebase Authentication API Token取得
export const getFirebaseAuthToken = async (isRefresh: boolean = true) => {
  return await auth.currentUser?.getIdToken(/* forceRefresh */ isRefresh);
};

// Firebaseユーザー情報取得
export const getFirebaseUserInfo = (): any => {
  return auth.currentUser !== null ? auth.currentUser : {};
};
