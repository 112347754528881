/**
 * mapInnerDataOperation.js
 * 内部で保持したデータの処理を行います
 */
import { point2distance } from "../../../apis/turfAction";
import { getGcsFile } from "../../../apis/axiosAction";
import { getGcsZipFile } from "../../../apis/depressAction";
import {
  isDisplaySejExistingStore,
  isDisplayConflictStore,
  isDisplayZone,
  isDisplayPlanArea,
} from "../MapComponents/MapsDisplayManager";
import {
  existingSejStoreLayer,
  cbFuncExistingSejStores,
  innerDataExistingSejStoreLayer,
} from "../layers/existingSejStoreLayer";
import {
  conflictStoreLayer,
  cbFuncConflictStores,
  setConflictStore,
  innerDataConflictStore,
} from "../layers/conflictStoreLayer";
import {
  planAreaLayer,
  cbFuncPlanArea,
  setPlanArea,
  innerDataPlanArea,
} from "../layers/planAreaLayer";
import {
  zoneLayer,
  cbFuncZone,
  setZone,
  innerDataZone,
} from "../layers/zoneLayer";

let lastQueriedLatLng: any = null;

// 内部データ処理の呼出
export const callMapItemInnerData = (
  latLng: any,
  zoomLevel = 22,
  isForceUpdate: boolean = false
) => {
  let searchLength = 3;
  const searchDistance = 3,
    largeAreaZoomLevel = 15;
  if (
    lastQueriedLatLng !== null &&
    point2distance(latLng, lastQueriedLatLng) / 1000 < searchDistance &&
    !isForceUpdate
  ) {
    return;
  }
  if (largeAreaZoomLevel > zoomLevel) {
    searchLength = 4.5;
  }

  if (isDisplaySejExistingStore) {
    callInnerData(
      cbFuncExistingSejStores,
      existingSejStoreLayer,
      innerDataExistingSejStoreLayer(latLng)
    );
  }
  if (isDisplayConflictStore) {
    callInnerData(
      cbFuncConflictStores,
      conflictStoreLayer,
      innerDataConflictStore(latLng)
    );
  }
  if (isDisplayZone) {
    callInnerData(cbFuncZone, zoneLayer, innerDataZone(latLng));
  }
  if (isDisplayPlanArea) {
    callInnerData(cbFuncPlanArea, planAreaLayer, innerDataPlanArea(latLng));
  }

  lastQueriedLatLng = latLng;
};

const callInnerData = (callbackFunc: any, layerValue: any, geojson: any) => {
  callbackFunc(geojson, layerValue, []);
};

/**
 * 初期処理より呼び出されデータを取得する
 */
export const initializeInnerData = (token: string): void => {
  getFromGcsZip(token, "/master/conflict_stores.zip", setConflictStore);
  getFromGcsZip(token, "/master/zone.zip", setZone);
  getFromGcsZip(token, "/master/plan_area.zip", setPlanArea);
};

/**
 * GoogleCloudStorageよりマスタ情報を取得する
 * @param token GoogleCloudStorageのトークン
 * @param func コールバック関数
 */
export const getFromGcs = (token: string, gcsPath: string, func: any) => {
  getGcsFile(
    "https://storage.googleapis.com/" +
      process.env.REACT_APP_FIREBASE_STORAGE_BUCKET +
      gcsPath,
    token
  ).then((res) => {
    func(res);
  });
};

/**
 * GoogleCloudStorageよりZipファイルのマスタ情報を取得して後続処理を行う
 * @param token GoogleCloudStorageのトークン
 * @param gcsPath GoogleCloudStorageのパス
 * @param func コールバック関数
 */
export const getFromGcsZip = (token: string, gcsPath: string, func: any) => {
  getGcsZipFile(
    "https://storage.googleapis.com/" +
      process.env.REACT_APP_FIREBASE_STORAGE_BUCKET +
      gcsPath,
    token,
    func
  );
};

export const csvSplit = (line: string) => {
  let c = "";
  let s = new String();
  let data = new Array();
  let singleQuoteFlg = false;

  for (var i = 0; i < line.length; i++) {
    c = line.charAt(i);
    if (c == "," && !singleQuoteFlg) {
      data.push(s.toString());
      s = "";
    } else if (c == "," && singleQuoteFlg) {
      s = s + c;
    } else if (c == '"') {
      singleQuoteFlg = !singleQuoteFlg;
    } else {
      s = s + c;
    }
  }
  return data;
};
