/**
 * モバ空マップ関係のコントローラークラスです。
 * @namespace MobakuMap
 * @class MobakuMap.ts
 * @constructor
 *
 * モバ空マップの名前空間を解決することが困難だったためmobakumapのエラーを無視して、
 * 名前空間エラーを無理やり突破しています
 */

export const createMobakuMap = (mapObject: any) => {
  console.log("NODE_ENV" + process.env.NODE_ENV);
  if (
    process.env.NODE_ENV === "development"
  ) {
    return;
  }
  console.log("mobaku-init-start")
  // @ts-ignore
  mobakumap.init(mapObject, {
    data_url: "https://sej.mobakumap.jp/data/",
    enable_history_mode: true,
    show_population_heatmap: true,
    show_population_timeline: true,
    show_population_age_gender: true,
    show_population_residence: true,
    show_residence_detail: true,
    show_residence_map: true,
    history_max: "13m",
    reduce_detail: true,
    hide_detail_marker: true,
  });
  console.log("mobaku-init-end")
};

// モバ空表示/非表示切り替え
let enabled = true;
export const toggle = () => {
  enabled = !enabled;
  console.log("mobaku-1")
  if (enabled) {
    console.log("mobaku-show-start");
    // @ts-ignore
    mobakumap.show();
    console.log("mobaku-show-end");
  } else {
    console.log("mobaku-hide-start");
    // @ts-ignore
    mobakumap.hide();
    console.log("mobaku-hide-end");
  }
  console.log("mobaku-2")
};
