/**
 * zoneLayer.ts
 * 地図上の表示管理を行います
 */

import { getGoogleMapsObject } from "../GoogleMaps";
import { calcPointsWithinCircle } from "../../../apis/turfAction";
import {
  unvisibleMapsStyleSettings,
  applyHtmlTemplate,
} from "../MapStylesSettings";
import { updateFireStoreLayer, manageDisplayLayer } from "../MapItems";
import { csvSplit } from "../operation/mapInnerDataOperation";

export let zoneLayer: google.maps.Data,
  zoneInfoWindow = new Map(),
  zoneData: any;

// 個別項目の型
export interface ZONE_RECORD {
  // 各アプリケーション共通項目
  id: string;
  name: string;
  longitude: string;
  latitude: string;
  shape: string;
  zone_townname: string;
  zone_type: string;
  status: string;
}

// 個別項目の型のオブジェクトの集まり
export interface INTERFACE_ZONE_DATA {
  [index: string]: ZONE_RECORD;
}

// ZONEの型
export interface INTERFACE_ZONE {
  data: INTERFACE_ZONE_DATA;
  count: number;
}

// ZONEの初期値
const initialZone: INTERFACE_ZONE = {
  data: {},
  count: 0,
};

const zone = {
  zone: initialZone,
};

// 全量を保存する
export const setZone = (res: any) => {
  const recordData = []; //配列を用意
  const csvRecords = res.split(/\r\n|\r|\n/); //改行で分割
  const data: any = {};
  let count = 0;
  for (let i = 0; i < csvRecords.length; i++) {
    recordData[i] = csvSplit(csvRecords[i]);
    if (recordData[i].length <= 1) {
      continue;
    }
    const record: ZONE_RECORD = {
      id: recordData[i][0],
      longitude: recordData[i][1],
      latitude: recordData[i][2],
      shape: recordData[i][3],
      name: recordData[i][4],
      zone_townname: recordData[i][5],
      zone_type: recordData[i][6],
      status: recordData[i][7],
    };
    data[recordData[i][0]] = record;
    count++;
  }
  const result: INTERFACE_ZONE = {
    data,
    count,
  };
  zone.zone = result;
};

/**
 * 内部データよりGoogleMapsに表示する
 */
export const innerDataZone = (latLng: google.maps.LatLng) => {
  const obj: INTERFACE_ZONE_DATA = zone.zone.data;
  const array: any = [];
  // GeoJSONに格納する配列をつくる
  Object.keys(obj).forEach(function (k) {
    array.push(
      turf.point([parseFloat(obj[k].longitude), parseFloat(obj[k].latitude)], {
        id: obj[k].id,
        name: obj[k].name,
        status: obj[k].status,
        shape: obj[k].shape,
      })
    );
  });
  const pointGeoJson = calcPointsWithinCircle(
    array,
    latLng.lng(),
    latLng.lat(),
    3
  );
  let featureArray: any = []; // 格納用の配列
  // @ts-ignore エラーが出るため回避
  turf.featureEach(pointGeoJson, function (currentFeature) {
    const jsonFeature = JSON.parse(
      currentFeature.properties.shape
        .replace("type", '"type"')
        .replace("Polygon", '"Polygon"')
        .replace("coordinates", '"coordinates"')
    );
    const geometry: any = {
      type: jsonFeature.type,
      coordinates: jsonFeature.coordinates,
    };
    const attribution = currentFeature.properties;
    featureArray.push(turf.feature(geometry, attribution));
  });
  return turf.featureCollection(featureArray);
};

// ZONE初期処理
let isApiRequest = false;
export const cbFuncZoneFirst = () => {
  if (isApiRequest) {
    return true;
  }
  isApiRequest = true;
  return false;
};

export const cbFuncZone = (geojson: any, updateLayer: any, conditions: any) => {
  zoneData = geojson;
  // setzone(
  //   replaceNewGeoJson(getzone(), geojson, conditions)
  // );
  updateFireStoreLayer(updateLayer);
  isApiRequest = false;
};

// 初期処理
export const initZone = (): void => {
  zoneLayer = new google.maps.Data({
    map: getGoogleMapsObject(),
    style: {
      visible: false,
    },
  });
  // クリックイベントの登録
  zoneLayer.addListener("click", function (event: any) {
    if (!zoneInfoWindow.has(event.feature)) {
      zoneInfoWindow.set(
        event.feature,
        new google.maps.InfoWindow({
          // maxWidth: getSystemParameterValue("googleMapsInfoWindowMaxWidth"),
          maxWidth: 300,
        })
      );
      // 表示位置
      zoneInfoWindow.get(event.feature).setPosition(event.latLng);
      // GeoJSONのPropertyから取得して、InfoWindow内の内容を設定する
      zoneInfoWindow
        .get(event.feature)
        .setContent(
          applyHtmlTemplate(zoneInfoWindowHtml, [
            event.feature.getProperty("id"),
            event.feature.getProperty("name"),
          ])
        );

      zoneInfoWindow
        .get(event.feature)
        .setOptions({ pixelOffset: new google.maps.Size(0, -25) });
      // InfowWindowを表示
      zoneInfoWindow.get(event.feature).open(getGoogleMapsObject());
      zoneInfoWindow.get(event.feature).addListener("domready", () => {
        // infowindowのZindex変更イベント
        // $(
        //   "#" +
        //     event.feature.getProperty("attribution").tenpo_code +
        //     "-infowindow"
        // ).on("click", () => {
        //   setZIndex(zoneInfoWindow.get(event.feature));
        // });
        // $("#testButtonInfoWindow")
        //   .off()
        //   .on("click", () => {
        //     // Todo暫定ルート検索
        //     getRoute();
        //   });
      });
      zoneInfoWindow.get(event.feature).addListener("closeclick", function () {
        zoneInfoWindow.delete(event.feature);
      });
    } else {
      zoneInfoWindow.get(event.feature).close();
      zoneInfoWindow.delete(event.feature);
    }
  });
  // スタイル指定（最初は非表示）
  zoneLayer.setStyle(unvisibleMapsStyleSettings);
  manageDisplayLayer(zoneLayer, false);
};

/**
 * GoogleMapsスタイル設定
 */
export const zoneLayerMapsStyleSettings = function (feature: any) {
  if (
    feature.getProperty("status") == "2" &&
    feature.getProperty("request_flag") == "0"
  ) {
    return {
      fillColor: "#fae8e1",
      strokeColor: "#faa884",
      strokeWeight: 1.0,
      visible: true,
    };
  } else {
    return {
      fillColor: "#cfe1e6",
      strokeColor: "#008db7",
      strokeWeight: 1.0,
      visible: true,
    };
  }
};

const zoneInfoWindowHtml =
  '<div class="info-window-padding"' +
  "<div >ID:{0}</div>" +
  "<div >名称:{1}</div>" +
  "</div>";
