import React, { useEffect } from "react";
import { createGoogleMap } from "./GoogleMaps";
import { toggle } from "./MobakuMap";
import MapsSearchBar from "./MapComponents/MapsSearchBar";
import MapsGeolocationButton from "./MapComponents/MapsGeolocationButton";
import MapsDisplayManager from "./MapComponents/MapsDisplayManager";
// import { manageDisplayLayerFromName } from "./MapItems";
// import { userLogging } from "../../apis/userLog";

const vh = window.innerHeight * 0.97;

const Maps: React.FC = (props: any) => {
  const [checked, setChecked] = React.useState(true);

  useEffect(() => {
    createGoogleMap();
    // userLogging("opview-map", "opview-map")
  }, []);

  const handleMobakuToggle = (flag: boolean) => {
    setChecked(!flag);
    toggle();
  };

  return (
    <div>
      <div
        id="map"
        style={{
          height: vh,
          width: "100%",
        }}
      ></div>
      <MapsSearchBar />
      <MapsGeolocationButton />
      <MapsDisplayManager />
      <div
        className={"mobaku-button"}
      >
        <label>
          <input
            type="checkbox"
            onChange={() => handleMobakuToggle(checked)}
            checked={checked}
          />
          モバ空表示
        </label>
      </div>
    </div>
  );
};

export default Maps;
