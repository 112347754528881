import React, { useEffect } from "react";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Avatar from "@mui/material/Avatar";
import { manageDisplayLayerFromName } from "../MapItems";
// import { userLogging } from "../../../apis/userLog";

export let isDisplaySejExistingStore: boolean = false,
  isDisplayConflictStore: boolean = false,
  isDisplayZone: boolean = false,
  isDisplayPlanArea: boolean = false,
  isDisplayCloseSejStore: boolean = false;

const MapsDisplayManager: React.FC = (props: any) => {
  const [isDisplay, setIsDisplay] = React.useState(true);
  const [isSejExistingStoreHooks, setIsSejExistingStoreHooks] =
    React.useState(isDisplaySejExistingStore);
  const [isConflictStoreHooks, setIsConflictStoreHooks] =
    React.useState(isDisplayConflictStore);
  const [isZoneHooks, setIsZoneHooks] =
    React.useState(isDisplayZone);
  const [isPlanAreaHooks, setIsPlanAreaHooks] =
    React.useState(isDisplayPlanArea);
  const [isClosedSejStoreHooks, setIsClosedSejStoreHooks] =
    React.useState(isDisplayCloseSejStore);
  // const classes = useStyles({});
  // const history = useHistory();
  useEffect(() => {}, []);
  const handleSearchMenu = (flag: boolean) => {
    setIsDisplay(flag);
  };
  // Todo 消す
  const handleSearchButton = () => {
    console.log("handleSearchButton");
  };
  const handleSejExistingStore = () => {
    isDisplaySejExistingStore = !isDisplaySejExistingStore;
    setIsSejExistingStoreHooks(isDisplaySejExistingStore);
    manageDisplayLayerFromName("existing_sej_stores", isDisplaySejExistingStore);
  };

  const handleConflictStore = () => {
    isDisplayConflictStore = !isDisplayConflictStore;
    setIsConflictStoreHooks(isDisplayConflictStore);
    manageDisplayLayerFromName("conflict_stores", isDisplayConflictStore);
  };

  const handleZone = () => {
    isDisplayZone = !isDisplayZone;
    setIsZoneHooks(isDisplayZone);
    manageDisplayLayerFromName("zone", isDisplayZone);
  };

  const handlePlanArea = () => {
    isDisplayPlanArea = !isDisplayPlanArea;
    setIsPlanAreaHooks(isDisplayPlanArea);
    manageDisplayLayerFromName("plan_area", isDisplayPlanArea);
  };

  const handleClosedSejStore = () => {
    isDisplayCloseSejStore = !isDisplayCloseSejStore;
    setIsClosedSejStoreHooks(isDisplayCloseSejStore);
    manageDisplayLayerFromName("plan_area", isDisplayCloseSejStore);
  };
  
  return (
    <>
      {isDisplay ? (
        <>
          <Grid container className={"display-manager-menu"}>
            <Grid container item xs={2}>
              <Button
                className={
                  isSejExistingStoreHooks
                    ? "display-manager-inner-button-on"
                    : "display-manager-inner-button-off"
                }
                onClick={() => handleSejExistingStore()}
              >
                既存店
              </Button>
            </Grid>
            <Grid container item xs={2}>
              <Button
                className={
                  isConflictStoreHooks
                    ? "display-manager-inner-button-on"
                    : "display-manager-inner-button-off"
                }
                onClick={() => handleConflictStore()}
              >
                競合店
              </Button>
            </Grid>
            <Grid container item xs={2}>
              <Button
                className={
                  isZoneHooks
                    ? "display-manager-inner-button-on"
                    : "display-manager-inner-button-off"
                }
                onClick={() => handleZone()}
              >
                ZONE
              </Button>
            </Grid>
            <Grid container item xs={2}>
              <Button
                className={
                  isPlanAreaHooks
                    ? "display-manager-inner-button-on"
                    : "display-manager-inner-button-off"
                }
                onClick={() => handlePlanArea()}
              >
                候補地
              </Button>
            </Grid>
            <Grid container item xs={2}>
              <Button
                className={
                  isClosedSejStoreHooks
                    ? "display-manager-inner-button-on"
                    : "display-manager-inner-button-off"
                }
                
                onClick={() => handleClosedSejStore()}
              >
                閉店
              </Button>
            </Grid>
            <Grid container item xs={1}></Grid>
            <Grid container item xs={1}>
              <IconButton
                onClick={() => handleSearchMenu(false)}
                className={"display-manager-menu-close"}
              >
                <Avatar
                  src="./image/icons/arrow_down.svg"
                  sx={{ width: 20, height: 20 }}
                />
              </IconButton>
            </Grid>
          </Grid>
        </>
      ) : (
        <IconButton
          aria-label="表示管理"
          className={"display-manager-button map-item-shadow"}
          onClick={() => handleSearchMenu(true)}
        >
          <Avatar
            src="./image/icons/arrow_up.svg"
            sx={{ width: 20, height: 20 }}
          />
        </IconButton>
      )}
    </>
  );
};

export default MapsDisplayManager;
