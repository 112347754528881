/**
 * planAreaLayer.ts
 * 地図上の表示管理を行います
 */

import { getGoogleMapsObject } from "../GoogleMaps";
import { calcPointsWithinCircle } from "../../../apis/turfAction";
import {
  unvisibleMapsStyleSettings,
  applyHtmlTemplate,
  zIndexOrder,
} from "../MapStylesSettings";
import { updateFireStoreLayer, manageDisplayLayer } from "../MapItems";
import { csvSplit } from "../operation/mapInnerDataOperation";

export let planAreaLayer: google.maps.Data,
  planAreaEntranceInfoWindow = new Map(),
  entranceData: any,
  planAreaData: any;

// 個別項目の型
export interface PLAN_AREA_RECORD {
  // 各アプリケーション共通項目
  id: string;
  name: string;
  longitude: string;
  latitude: string;
  shape_building: string;
  shape_plan_area: string;
  request_flag: string;
  edit_flag: string;
  corporate_flag: string;
  plan_area_name: string;
  step: string;
  plan_area_address: string;
  plan_area_townname: string;
  plan_area_townname_serial_number: string;
  plan_area_area_size: string;
  building_area_size: string;
  plan_area_current_state: string;
}

// 個別項目の型のオブジェクトの集まり
export interface INTERFACE_PLAN_AREA_DATA {
  [index: string]: PLAN_AREA_RECORD;
}

// 候補地の型
export interface INTERFACE_PLAN_AREA {
  data: INTERFACE_PLAN_AREA_DATA;
  count: number;
}

// 候補地の初期値
const initialPlanArea: INTERFACE_PLAN_AREA = {
  data: {},
  count: 0,
};

const planArea = {
  planArea: initialPlanArea,
};

// 全量を保存する
export const setPlanArea = (res: any) => {
  const recordData = []; //配列を用意
  const csvRecords = res.split(/\r\n|\r|\n/); //改行で分割
  const data: any = {};
  let count = 0;
  for (let i = 0; i < csvRecords.length; i++) {
    recordData[i] = csvSplit(csvRecords[i]);
    if (recordData[i].length <= 10) {
      continue;
    }
    const record: PLAN_AREA_RECORD = {
      id: recordData[i][0],
      longitude: recordData[i][1],
      latitude: recordData[i][2],
      shape_building: recordData[i][3],
      shape_plan_area: recordData[i][4],
      request_flag: recordData[i][5],
      edit_flag: recordData[i][6],
      corporate_flag: recordData[i][7],
      plan_area_name: recordData[i][8],
      step: recordData[i][9],
      plan_area_address: recordData[i][11],
      plan_area_townname: recordData[i][12],
      plan_area_townname_serial_number: recordData[i][13],
      plan_area_area_size: recordData[i][14],
      building_area_size: recordData[i][15],
      plan_area_current_state: recordData[i][16],
      name: recordData[i][8],
    };
    data[recordData[i][0]] = record;
    count++;
  }
  const result: INTERFACE_PLAN_AREA = {
    data,
    count,
  };
  planArea.planArea = result;
};

/**
 * 内部データよりGoogleMapsに表示する
 */
export const innerDataPlanArea = (latLng: google.maps.LatLng) => {
  const obj: INTERFACE_PLAN_AREA_DATA = planArea.planArea.data;
  const array: any = [];
  // GeoJSONに格納する配列をつくる
  Object.keys(obj).forEach(function (k) {
    array.push(
      turf.point([parseFloat(obj[k].longitude), parseFloat(obj[k].latitude)], {
        id: obj[k].id,
        name: obj[k].name,
        step: obj[k].step,
        plan_area_name: obj[k].plan_area_name,
        request_flag: obj[k].request_flag,
        shape_building: obj[k].shape_building,
        shape_plan_area: obj[k].shape_plan_area,
      })
    );
  });
  const pointGeoJson = calcPointsWithinCircle(
    array,
    latLng.lng(),
    latLng.lat(),
    3
  );
  let featureArray: any = []; // 格納用の配列
  // @ts-ignore エラーが出るため回避
  turf.featureEach(pointGeoJson, function (currentFeature) {
    const attribution = Object.assign({}, currentFeature.properties);
    if (
      attribution.shape_building === "" ||
      attribution.shape_plan_area === ""
    ) {
      // 仮候補地の場合
      const geometry: any = {
        type: currentFeature.geometry.type,
        coordinates: currentFeature.geometry.coordinates,
      };
      const entranceAttribution = currentFeature.properties;
      entranceAttribution["plan_area_type"] = "entrance";
      featureArray.push(turf.feature(geometry, entranceAttribution));
    } else {
      // 本候補地の場合
      let buildingAttribution = Object.assign({}, currentFeature.properties);
      let planAreaAttribution = Object.assign({}, currentFeature.properties);
      const geometry: any = {
        type: currentFeature.geometry.type,
        coordinates: currentFeature.geometry.coordinates,
      };
      const entranceAttribution = currentFeature.properties;
      entranceAttribution["plan_area_type"] = "entrance";
      featureArray.push(turf.feature(geometry, entranceAttribution));
      const buildingJsonFeature = JSON.parse(
        currentFeature.properties.shape_building
          .replace("type", '"type"')
          .replace("Polygon", '"Polygon"')
          .replace("coordinates", '"coordinates"')
      );
      const buildingGeometry: any = {
        type: buildingJsonFeature.type,
        coordinates: buildingJsonFeature.coordinates,
      };

      buildingAttribution["plan_area_type"] = "building";
      featureArray.push(turf.feature(buildingGeometry, buildingAttribution));
      const planAreaJsonFeature = JSON.parse(
        currentFeature.properties.shape_plan_area
          .replace("type", '"type"')
          .replace("Polygon", '"Polygon"')
          .replace("coordinates", '"coordinates"')
      );
      const planAreaGeometry: any = {
        type: planAreaJsonFeature.type,
        coordinates: planAreaJsonFeature.coordinates,
      };
      planAreaAttribution["plan_area_type"] = "plan_area";
      featureArray.push(turf.feature(planAreaGeometry, planAreaAttribution));
    }
  });
  return turf.featureCollection(featureArray);
};

// 候補地初期処理
let isApiRequest = false;
export const cbFuncPlanAreaFirst = () => {
  if (isApiRequest) {
    return true;
  }
  isApiRequest = true;
  return false;
};

export const cbFuncPlanArea = (
  geojson: any,
  updateLayer: any,
  conditions: any
) => {
  planAreaData = geojson;
  isApiRequest = false;
  updateFireStoreLayer(updateLayer);
};

// 初期処理
export const initPlanArea = (): void => {
  planAreaLayer = new google.maps.Data({
    map: getGoogleMapsObject(),
    style: {
      visible: false,
    },
  });
  // クリックイベントの登録
  planAreaLayer.addListener("click", function (event: any) {
    if (!planAreaEntranceInfoWindow.has(event.feature)) {
      planAreaEntranceInfoWindow.set(
        event.feature,
        new google.maps.InfoWindow({
          // maxWidth: getSystemParameterValue("googleMapsInfoWindowMaxWidth"),
          maxWidth: 300,
        })
      );
      // 表示位置
      planAreaEntranceInfoWindow.get(event.feature).setPosition(event.latLng);
      // GeoJSONのPropertyから取得して、InfoWindow内の内容を設定する
      planAreaEntranceInfoWindow
        .get(event.feature)
        .setContent(
          applyHtmlTemplate(planAreaInfoWindowHtml, [
            event.feature.getProperty("id"),
            event.feature.getProperty("plan_area_name"),
            event.feature.getProperty("step"),
          ])
        );

      planAreaEntranceInfoWindow
        .get(event.feature)
        .setOptions({ pixelOffset: new google.maps.Size(0, -25) });
      // InfowWindowを表示
      planAreaEntranceInfoWindow.get(event.feature).open(getGoogleMapsObject());
      planAreaEntranceInfoWindow
        .get(event.feature)
        .addListener("domready", () => {
          // infowindowのZindex変更イベント
          // $(
          //   "#" +
          //     event.feature.getProperty("attribution").tenpo_code +
          //     "-infowindow"
          // ).on("click", () => {
          //   setZIndex(planAreaInfoWindow.get(event.feature));
          // });
          // $("#testButtonInfoWindow")
          //   .off()
          //   .on("click", () => {
          //     // Todo暫定ルート検索
          //     getRoute();
          //   });
        });
      planAreaEntranceInfoWindow
        .get(event.feature)
        .addListener("closeclick", function () {
          planAreaEntranceInfoWindow.delete(event.feature);
        });
    } else {
      planAreaEntranceInfoWindow.get(event.feature).close();
      planAreaEntranceInfoWindow.delete(event.feature);
    }
  });
  // スタイル指定（最初は非表示）
  planAreaLayer.setStyle(unvisibleMapsStyleSettings);
  manageDisplayLayer(planAreaLayer, false);
};

/**
 * GoogleMapsスタイル設定
 */
const unvisibleZoomLevel = 10;
export const planAreaLayerMapsStyleSettings = function (feature: any) {
  if (feature.getProperty("plan_area_type") === "plan_area") {
    let flagVisible = true;
    if (
      feature.getProperty("step") === "8" ||
      feature.getProperty("step") === "99" ||
      Number(localStorage.getItem("lastZoomLevel")) < unvisibleZoomLevel
    ) {
      flagVisible = false;
    }
    return {
      clickable: false,
      editable: false,
      fillColor: "#FF0000",
      fillOpacity: 0.1,
      strokeWeight: 1.0,
      strokeColor: "#FF0000",
      visible: flagVisible,
      zIndex: zIndexOrder.kouhochiPolygon,
    };
  } else if (feature.getProperty("plan_area_type") === "building") {
    let flagVisible = true;
    if (
      feature.getProperty("step") === "8" ||
      feature.getProperty("step") === "99" ||
      Number(localStorage.getItem("lastZoomLevel")) < unvisibleZoomLevel
    ) {
      flagVisible = false;
    }
    return {
      clickable: false,
      editable: false,
      fillColor: "#000000",
      fillOpacity: 0.1,
      strokeWeight: 1.0,
      strokeColor: "#000000",
      visible: flagVisible,
      zIndex: zIndexOrder.buildingPolygon,
    };
  } else if (feature.getProperty("plan_area_type") === "entrance") {
    let icon = {
      url: "./image/icons/double_circle.svg",
      anchor: new google.maps.Point(15, 15),
    };
    let flagVisible = true;
    if (
      feature.getProperty("zone_id") === "" ||
      feature.getProperty("step") === "1"
    ) {
      icon.url = "./image/icons/double_circle_purple.svg";
    }
    if (
      feature.getProperty("step") === "0" ||
      feature.getProperty("step") === "44"
    ) {
      icon.url = "./image/icons/double_circle_gray.svg";
    }
    if (feature.getProperty("step") === "7") {
      icon.url = "./image/icons/sej_cleanness.png";
    }
    if (
      feature.getProperty("step") === "8" ||
      feature.getProperty("step") === "99"
    ) {
      flagVisible = false;
    }
    return {
      icon: icon,
      visible: flagVisible,
      zIndex: zIndexOrder.buildingEntrance,
    };
  }
};

const planAreaInfoWindowHtml =
  '<div class="info-window-padding"' +
  "<div >ID:{0}</div>" +
  "<div >候補地名:{1}</div>" +
  "<div >STEP:{2}</div>" +
  "</div>";
