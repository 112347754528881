/**
 * 共通で使用可能なデータをここに配置します
 * (デバイス関係)
 *
 */
type deviceType = {
  // デバイスの種別 True :モバイル、False:PC
  isDeviceType: boolean;
  // iOSの判定(True :iOS、False:Android)
  isIos: boolean;
  // iOSの判定(True :iOS、False:Android)
  isIPhone: boolean;
};

export const deviceData: deviceType = {
  isDeviceType: false,
  isIos: false,
  isIPhone: false,
};

// iOSかどうかを取得します。
export const getDeviceData = ():deviceType => {
  return deviceData;
};

// デバイス・OSの判定を行う
export const setInitialInfo = () => {
  const ua = navigator.userAgent;
  const loerUa = navigator.userAgent.toLowerCase();
  console.log(deviceData.isIPhone);
  if (ua.indexOf("iPhone") > -1) {
    // iPhone
    deviceData.isDeviceType = true;
    deviceData.isIos = true;
    deviceData.isIPhone = true;
  } else if (ua.indexOf("Android") > -1 && ua.indexOf("Mobile") > -1) {
    // Android(スマホ)
    deviceData.isDeviceType = true;
    deviceData.isIos = true;
    deviceData.isIPhone = false;
  } else if (ua.indexOf("iPad") > -1) {
    // iPad
    deviceData.isDeviceType = true;
    deviceData.isIos = true;
    deviceData.isIPhone = false;
  } else if (/ipad|macintosh/.test(loerUa) && "ontouchend" in document) {
    // OS判定がMacOSX向けになっているもの
    // iPad
    deviceData.isDeviceType = true;
    deviceData.isIos = true;
    deviceData.isIPhone = false;
  } else if (ua.indexOf("Android") > -1) {
    // Android(タブレット)
    deviceData.isDeviceType = false;
    deviceData.isIos = false;
    deviceData.isIPhone = false;
  } else {
    // PC
    deviceData.isDeviceType = true;
    deviceData.isIos = false;
    deviceData.isIPhone = false;
  }
};
