/**
 * closedSejStore.ts
 * 地図上の表示管理を行います
 */

import { getGoogleMapsObject } from "../GoogleMaps";
import {
  unvisibleMapsStyleSettings,
  applyHtmlTemplate,
  zIndexOrder
} from "../MapStylesSettings";
import { updateFireStoreLayer, manageDisplayLayer } from "../MapItems";

export let closedSejStoreLayer: google.maps.Data,
  closedSejStoreInfoWindow = new Map(),
  closedSejStoreData: any;

// 閉店店舗初期処理
let isApiRequest = false;
export const cbFuncClosedSejStoreFirst = () => {
  if (isApiRequest) {
    return true;
  }
  isApiRequest = true;
  return false;
};

export const cbFuncClosedSejStore = (geojson: any, updateLayer: any, conditions: any) => {
  closedSejStoreData = geojson;
  updateFireStoreLayer(updateLayer);
  isApiRequest = false;
};

// 初期処理
export const initClosedSejStore = (): void => {
  closedSejStoreLayer = new google.maps.Data({
    map: getGoogleMapsObject(),
    style: {
      visible: false,
    },
  });
  // クリックイベントの登録
  closedSejStoreLayer.addListener("click", function (event: any) {
    if (!closedSejStoreInfoWindow.has(event.feature)) {
      closedSejStoreInfoWindow.set(
        event.feature,
        new google.maps.InfoWindow({
          // maxWidth: getSystemParameterValue("googleMapsInfoWindowMaxWidth"),
          maxWidth: 300,
        })
      );
      // 表示位置
      closedSejStoreInfoWindow.get(event.feature).setPosition(event.latLng);
      // GeoJSONのPropertyから取得して、InfoWindow内の内容を設定する
      closedSejStoreInfoWindow
        .get(event.feature)
        .setContent(
          applyHtmlTemplate(closedSejStoreInfoWindowHtml, [
            event.feature.getProperty("attribution").store_code,
            event.feature.getProperty("attribution").store_name,
            event.feature.getProperty("attribution").openingdate,
            event.feature.getProperty("attribution").contract_type,
          ])
        );

      closedSejStoreInfoWindow
        .get(event.feature)
        .setOptions({ pixelOffset: new google.maps.Size(0, -25) });
      // InfowWindowを表示
      closedSejStoreInfoWindow.get(event.feature).open(getGoogleMapsObject());
      closedSejStoreInfoWindow.get(event.feature).addListener("domready", () => {
        // infowindowのZindex変更イベント
        // $(
        //   "#" +
        //     event.feature.getProperty("attribution").tenpo_code +
        //     "-infowindow"
        // ).on("click", () => {
        //   setZIndex(closedSejStoreInfoWindowHtml.get(event.feature));
        // });
        // $("#testButtonInfoWindow")
        //   .off()
        //   .on("click", () => {
        //     // Todo暫定ルート検索
        //     getRoute();
        //   });
      });
      closedSejStoreInfoWindow.get(event.feature).addListener("closeclick", function () {
        closedSejStoreInfoWindow.delete(event.feature);
      });
    } else {
      closedSejStoreInfoWindow.get(event.feature).close();
      closedSejStoreInfoWindow.delete(event.feature);
    }
  });
  // スタイル指定（最初は非表示）
  closedSejStoreLayer.setStyle(unvisibleMapsStyleSettings);
  manageDisplayLayer(closedSejStoreLayer, false);
};

/**
 * GoogleMapsスタイル設定
 */
export const closedSejStoreLayerMapsStyleSettings = function (feature: any) {
  // 日単位でしかキャッシュが残らないようにする
  const date = new Date();
  const dateParam =
    date.getFullYear() +
    (date.getMonth() + 1).toString().padStart(2, "0") +
    date.getDate().toString().padStart(2, "0");
  return {
    icon: "./image/icons/sej_closed.png?date=" + dateParam,
    visible: true,
    zIndex: zIndexOrder.closedSejStoreIcon,
  };
};

const closedSejStoreInfoWindowHtml =
  '<div class="info-window-padding"' +
  "<div >店番:{0}</div>" +
  "<div >店名:{1}</div>" +
  "<div >開店日:{2}</div>" +
  "<div >タイプ:{3}</div>" +
  "</div>";
