import React, { useEffect } from "react";
import IconButton from "@mui/material/IconButton";
import NearMeIcon from '@mui/icons-material/NearMe';
import { moveGMapsPoint } from "../GoogleMaps";
import { getGeolocation } from "../../../apis/geolocationAction";
// import { userLogging } from "../../../apis/userLog";

const MapsGeolocationButton: React.FC = (props: any) => {
  // const history = useHistory();
  useEffect(() => {}, []);
  const handleGeolocation = () => {
    getGeolocation()
      .then((position: any) => {
        moveGMapsPoint(position.coords.latitude, position.coords.longitude);
        // userLogging("opview-geo-location", "");
      })
      .catch((err:any) => {
        alert("現在地情報が取得できませんでした");
        // userLogging("opview-geo-location-error", `code:${err.code},message:${err.message}`);
      });
  };

  return (
    <IconButton
      className={"geolocation-button map-item-shadow"}
      onClick={() => handleGeolocation()}
    >
      <NearMeIcon fontSize="small" />
    </IconButton>
  );
};

export default MapsGeolocationButton;
