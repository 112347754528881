/**
 * existingSejStoreLayer.ts
 * 地図上の既存店の表示管理を行います
 */

import { getGoogleMapsObject } from "../GoogleMaps";
import { calcPointsWithinCircle } from "../../../apis/turfAction";
import {
  unvisibleMapsStyleSettings,
  applyHtmlTemplate,
  zIndexOrder,
} from "../MapStylesSettings";
import { updateFireStoreLayer, manageDisplayLayer } from "../MapItems";
import {
  getAllExistingSejStores,
  INTERFACE_STORE_DATA,
} from "../../../data/existingSejStores";

export let existingSejStoreLayer: google.maps.Data,
  existingSejStoreInfoWindow = new Map(),
  existingSejStoreData: any;

// 既存店舗処理
let isApiRequest = false;
export const cbFuncExistingSejStoresFirst = () => {
  if (isApiRequest) {
    return true;
  }
  isApiRequest = true;
  return false;
};

export const cbFuncExistingSejStores = (
  geojson: any,
  updateLayer: any,
  conditions: any
) => {
  existingSejStoreData = geojson;
  updateFireStoreLayer(updateLayer);
  isApiRequest = false;
};

// 既存店舗初期処理
export const initExistingSejStores = (): void => {
  existingSejStoreLayer = new google.maps.Data({
    map: getGoogleMapsObject(),
    style: {
      visible: false,
    },
  });
  // クリックイベントの登録
  existingSejStoreLayer.addListener("click", function (event: any) {
    if (!existingSejStoreInfoWindow.has(event.feature)) {
      existingSejStoreInfoWindow.set(
        event.feature,
        new google.maps.InfoWindow({
          // maxWidth: getSystemParameterValue("googleMapsInfoWindowMaxWidth"),
          maxWidth: 300,
        })
      );
      // 表示位置
      existingSejStoreInfoWindow.get(event.feature).setPosition(event.latLng);
      // GeoJSONのPropertyから取得して、InfoWindow内の内容を設定する
      existingSejStoreInfoWindow
        .get(event.feature)
        .setContent(
          applyHtmlTemplate(existingSejStoreInfoWindowHtml, [
            event.feature.getProperty("tenpo_code"),
            event.feature.getProperty("tenpo_name"),
            event.feature.getProperty("contract_type"),
          ])
        );

      existingSejStoreInfoWindow
        .get(event.feature)
        .setOptions({ pixelOffset: new google.maps.Size(0, -25) });
      // InfowWindowを表示
      existingSejStoreInfoWindow.get(event.feature).open(getGoogleMapsObject());
      existingSejStoreInfoWindow
        .get(event.feature)
        .addListener("domready", () => {
          // infowindowのZindex変更イベント
          // $(
          //   "#" +
          //     event.feature.getProperty("attribution").tenpo_code +
          //     "-infowindow"
          // ).on("click", () => {
          //   setZIndex(existingSejStoreInfoWindow.get(event.feature));
          // });
          // $("#testButtonInfoWindow")
          //   .off()
          //   .on("click", () => {
          //     // Todo暫定ルート検索
          //     getRoute();
          //   });
        });
      existingSejStoreInfoWindow
        .get(event.feature)
        .addListener("closeclick", function () {
          existingSejStoreInfoWindow.delete(event.feature);
        });
    } else {
      existingSejStoreInfoWindow.get(event.feature).close();
      existingSejStoreInfoWindow.delete(event.feature);
    }
  });
  // スタイル指定（最初は非表示）
  existingSejStoreLayer.setStyle(unvisibleMapsStyleSettings);
  manageDisplayLayer(existingSejStoreLayer, false);
};

/**
 * 内部データよりGoogleMapsに表示する
 */
export const innerDataExistingSejStoreLayer = (latLng: google.maps.LatLng) => {
  const obj: INTERFACE_STORE_DATA = getAllExistingSejStores().store_data;
  const array: any = [];
  // GeoJSONに格納する配列をつくる
  Object.keys(getAllExistingSejStores().store_data).forEach(function (k) {
    array.push(
      turf.point([parseFloat(obj[k].longitude), parseFloat(obj[k].latitude)], {
        tenpo_code: obj[k].tenpo_code,
        tenpo_name: obj[k].tenpo_name,
        contract_type: obj[k].contract_type,
      })
    );
  });
  return calcPointsWithinCircle(array, latLng.lng(), latLng.lat(), 5);
};

/**
 * GoogleMapsスタイル設定
 */
export const existingSejStoreLayerMapsStyleSettings = function (feature: any) {
  // 日単位でしかキャッシュが残らないようにする
  const date = new Date();
  const dateParam =
    date.getFullYear() +
    (date.getMonth() + 1).toString().padStart(2, "0") +
    date.getDate().toString().padStart(2, "0");
  return {
    icon: "./image/icons/sej_normal.png?date=" + dateParam,
    visible: true,
    zIndex: zIndexOrder.existingSejStoreIcon,
  };
};

const existingSejStoreInfoWindowHtml =
  '<div class="info-window-padding"' +
  "<div >店番:{0}</div>" +
  "<div >店名:{1}</div>" +
  // "<div >開店日:{2}</div>" +
  "<div >タイプ:{2}</div>" +
  "</div>";
