import React, { useState } from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { auth, analytics } from "../../firebase";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";

// 本番環境は地図画面が初期画面
const startMenu = process.env.REACT_APP_MODE !== "develop" ? "maps" : "home";

const Auth: React.FC = (props: any) => {
  const [userid, setUserid] = useState("");
  const [password, setPassword] = useState("");
  const [authFlg, setAuthFlg] = useState(true);
  const [loginMessage, setLoginMessage] = useState("");
  const navigate = useNavigate();

  const signIn = async () => {
    let inputUserId: string = userid;
    if (inputUserId.indexOf("@") === -1) {
      // 「@」が含まれない場合は「@opview.7view.jp」を付与する
      inputUserId = userid + "@mega.7view.jp";
    }
    await auth.signInWithEmailAndPassword(inputUserId, password)
      .then(() => {
        setLoginMessage("ログインに成功しました");
        navigate("/");
        // analytics.logEvent("login");
      })
      .catch((error:any) => {
        setAuthFlg(false);
        if (error.code === "auth/wrong-password") {
          setLoginMessage("パスワードが違います");
        } else if (error.code === "auth/invalid-email") {
          setLoginMessage("登録されていないIDです");
        } else {
          setLoginMessage("ログインに失敗しました");
        }
        console.log(error.code);
        console.log(error.message);
      });
  };

  // 開発画面以外ではログイン画面を非表示化する(本番環境はSSO共通基盤認証よりログイン)
  const isAuthDisplay: boolean = process.env.REACT_APP_MODE === "develop";

  return (
    <Grid container>
      <Grid container item xs={12} md={10} lg={8} xl={6}>
        {isAuthDisplay ? (
          <Card>
            <CardHeader
              title="MEGAセブンVIEW"
            />
            <Grid container>
              <img
                src={`${window.location.origin}/sejlogo.png`}
                alt="SEJアイコン"
                style={{ height: "15vh" }}
              />
            </Grid>
            <CardContent>
              <Grid container>
                <TextField
                  margin="dense"
                  required
                  label="ユーザーID"
                  variant="outlined"
                  style={{ width: "72vw" }}
                  id="userid"
                  name="userid"
                  autoComplete="userid"
                  value={userid}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setUserid(e.target.value);
                  }}
                />
                <TextField
                  margin="dense"
                  required
                  label="パスワード"
                  type="password"
                  variant="outlined"
                  style={{ width: "72vw" }}
                  name="password"
                  id="password"
                  autoComplete="current-password"
                  value={password}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setPassword(e.target.value);
                  }}
                />
              </Grid>
              <Typography>
                {loginMessage}
              </Typography>
            </CardContent>
            <CardActions>
              <Button
                size="large"
                variant="contained"
                onClick={async () => {
                  try {
                    await signIn();
                  } catch (err) {
                    console.log(err);
                  }
                }}
              >
                ログイン
              </Button>
            </CardActions>
          </Card>
        ) : (
          <></>
        )}
      </Grid>
    </Grid>
  );
};
export default Auth;
