/**
 * 共通で使用可能なデータをここに配置します
 * (アプリケーション情報関係)
 *
 */
type applicationInfo = {
  // GoogleCloudStorageのトークン情報
  gcsToken: string;
};

export const applicationInfo: applicationInfo = {
  gcsToken: "",
};

// アプリケーション情報を返します。
export const getApplicationInfo = (): applicationInfo => {
  return applicationInfo;
};

// GoogleCloudStorageのトークンを返します。
export const getGcsToken = (): string => {
  return applicationInfo.gcsToken;
};

// GoogleCloudStorageのトークンを設定します
export const setGcsToken = (token: string) => {
  applicationInfo.gcsToken = token;
};
