import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { auth } from "./firebase";
import CircularProgress from "@mui/material/CircularProgress";
import { useNavigate } from "react-router-dom";
import "./App.css";
import { getParam } from "./components/Auth/Login";
import {
  getFirestoreDocData,
  snapshotFirestoreDocData,
} from "./apis/firestoreAction";
import { initializeInnerData } from "./components/Maps/operation/mapInnerDataOperation";
import { setGcsToken } from "./data/applicationInfo";
import { setInitialInfo } from "./data/device";
import { initializeExistingSejStores } from "./data/existingSejStores";

const App: React.FC = (props: any) => {
  const dispatch = useDispatch();
  const [isAnotherLoginLoading, setIsAnotherLoginLoading] =
    React.useState(false);
  const navigate = useNavigate();
  let gcsToken: string = "";

  // 初期処理の終了フロー
  const setFirstOperationFinish = () => {
    // アプリケーション情報のFirestore更新イベントをハンドリング
    const funcUpdateAllUsers = (doc: any) => {
      setGcsToken(doc.data().token);
    };
    snapshotFirestoreDocData(
      "application_info",
      "all_users",
      funcUpdateAllUsers
    );
    // レイヤデータを裏で取得する
    initializeInnerData(gcsToken);
    setIsAnotherLoginLoading(false);
    navigate("/maps");
  };

  useEffect(() => {
    setIsAnotherLoginLoading(true);
    const unSub = auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        // 初期処理
        initialOperation(authUser);
      } else {
        if (
          process.env.REACT_APP_MODE === "develop" &&
          getParam("token", window.location.href) === ""
        ) {
          navigate("login");
        }
        setIsAnotherLoginLoading(false);
      }
    });
    return () => {
      unSub();
    };
  }, [dispatch, props.history]);

  // 初期処理
  const initialOperation = async (authUser: any): Promise<void> => {
    // ユーザーデータの取得
    // デバイス情報設定
    setInitialInfo();
    // Firestoreよりアプリケーション情報取得
    getFirestoreDocData("application_info", "all_users").then((doc: any) => {
      gcsToken = doc.token;
      initializeExistingSejStores(doc.token, setFirstOperationFinish);
    });
  };

  return (
    <>
      {isAnotherLoginLoading ? (
        <div
          style={{
            justifyContent: "center",
            display: "flex",
          }}
        >
          <CircularProgress
            size={"40vh"}
            style={{
              color: "#ccc",
              top: "30vh",
              position: "absolute",
            }}
          />
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default App;
